#prof-img-edit{
    object-fit: cover;
    width: 100px;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}

#prof-img{
    padding-left: 10px;
    padding-bottom: 20px;
}

#upload-wrapper{
    background-color: #222222;
    padding: 10px;
    width: 60vw;
}

#upload-back{
    margin-top: 20px;
}

#upload-message{
    text-align: center;
    margin-top: 20px;
}

#style-button-submit{
    width: 30%;
    display: inline-block;
    margin-right: 30px;
    padding-left: 150px;
}

#style-button-back{
    width: 10%;
    min-width: 40px;
    display: inline-block;
}