.addGame{
    color: #ffffff;
    font-size: xx-large;
}

.addGame #link-header {
    color: #ffffff;
    font-size: 24px;
    margin: 0px;
    margin-right: 15px;
}

.optBox{
    font-size: medium;
    color: #222222;
    padding: 20px;
    opacity: 1;
}

.linkBox{
    opacity: 1;
    background-color: #222222;
    min-width: 400px;
    min-height: 350px;
    width: 60vw;
    height: 10vh;
}

.linkBox #labelHeader{
    max-width: 40%;
}

.linkBox label select {
}

#plays-league-img{
    margin-top: 5px;
    height: 100px;
    display: flex;
}

#add-game-icon{
    display: flex;
    margin-left: 20px;
    margin-top: 5px;
    cursor: pointer;
}

#add-games-wrapper{
    display: flex;
}