.match-wrapper{
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width:420px;
    /*border: 1px solid black;*/
    color: #ffffff;
    background-color: #666666;
    margin: 10px auto;
    padding: 10px;
    font-size: 12.5pt;
}

.match-top-row{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.match-username{
    width: 50%;
    font-size: 20pt;
}
.match-username a{
    color: #ffffff;
    text-decoration: none;
}
.match-username a:hover{
    color: #F9C846;
}
.match-view-prof{
    flex:3;
    align-items:end;
}
.match-btn-holder{
    flex:3;
}
.match-msg-btn{
    height: 30px;
    aspect-ratio: 1;
    margin-left: auto;
    padding-bottom: 5px;
    padding-right: 5px;
}
.match-msg-btn:hover{
    cursor: pointer;
}

#envelopeIcon {
    color: #ffffff;
}

.match-msg-btn:hover #envelopeIcon{
    color:#F9C846;
}

.match-send-ico{
    width: 100%;
}

.match-mid-row{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.ico-box{
    width:100px;
    aspect-ratio: 1/1;
}
.match-ico{
    width:100px;
    aspect-ratio: 1/1;
}

.info-row-top{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid black;
}
.info-row-bot{
    display: flex;
    flex-direction: row;
}
.middle-info-wrapper{
    flex:1;
    margin-left: 10px;
}
.middle-info-rank{
    font-size: 20px;
}

.match-main-role{
    padding-bottom: 8px;
}

.mid-one{
    flex: 2;
}
.mid-two{
    flex: 1;
    text-align: center;
    padding-left: 10px;
    border-left: 1px solid black;
}

.match-mid-footer{
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    border-bottom: 1px solid black;
}
.match-last-online{
    flex:1;
}
.match-language{
    flex:1;
}

.large-font-size{
    font-size: 14pt;
}

.small-font-size{
    font-size: 11pt;
}



.match-info{
    flex: 10;
    padding-left: 4px;
    height: 200px;
    background-color: antiquewhite;
}

.sendmsg {
    padding-top: 20px;
}