.match-msg-wrapper{
    width: clamp(400px, 40%, 750px);
    background-color: #494949;
    padding: 6px;
    padding-bottom: calc(0.83em + 6px);
    border: 1px solid black;
}

.sender-container{
    width: 100%;
    margin:auto;
}

.match-msg-close{
    width: 50%;
    margin: auto;
}