.back-button{
    flex: 1;
    color: white;
    text-align: center;
    font-size: 16px;
    background-color: #666666;
    padding: 8px 0px;
    border: 1px solid black;
}
.back-button:hover{
    cursor: pointer;
    background-color: #888888;
}