.header {
    background: #272727;
    height: 85px;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem calc((100vw - 500px) / 4);
    z-index: 12;
}

.nav-wrapper {
    display: flex;
    align-items: center;
    margin-right: -24px;
}

.nav-wrapper a {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
}

.headertext {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
}

.headertext:hover{
    color: #F9C846;
}

.nav-wrapper a:hover {
    color: #F9C846;
}

#icon {
    padding-top: 2.5px;
}