.admin-wrapper{
    padding-bottom: 16px;
}

.reported-users-title{
    text-align: center;
}

.reported-users, .suspended-users{
    width: 90%;
    margin: 0px auto;
    border-collapse: collapse;
    color: black;
}
.reported-users td, .suspended-users td{
 padding-left: 10px;
}

.reported-users-head, .suspended-users-head{
    font-size: 22px;
    background-color: #BBBBBB;
}

.view-reports{
    flex: 1;
    color: white;
    text-align: center;
    font-size: 14px;
    background-color: #666666;
    padding: 5px 0px;
    border: 1px solid black;
}
.view-reports:hover{
    cursor: pointer;
    background-color: #888888;
}

.single-user-reports{
    width: 90%;
    margin: 0px auto;
}

.report-box{
    width: 100%;
    height: 30px;
    display: flex;
    background-color: #CCCCCC;
    margin-top: 4px;
    text-align: left;
    align-items: center;
}
.report-box:hover{
    cursor: pointer;
    background-color: #BBBBBB;
}
.report-timestamp{
    flex: 1;
}
.report-see-more{
    height: 80%;
}

.report-content{
    width: 100%;
    background-color: #CCCCCC;
    text-align: left;
    color: #000000;
    overflow: hidden;
}

.admin-header{
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
}

.admin-header h1{
    flex : 10;
    color: white;
}

.admin-nav-wrapper{
    width: 80%;
    margin: auto;
}

.ban-button{
    flex: 1;
    color: white;
    text-align: center;
    font-size: 16px;
    background-color: #ff6060;
    padding: 8px 0px;
}
.ban-button:hover{
    cursor: pointer;
    background-color: #ff7373;
}

.reported-messages-box{
    width: clamp(800px ,80vw,1200px);
    background-color: #282c34;
}

.ban-box-title{
    font-size: 24pt;
}
.ban-popup-box{
    width: clamp(400px, 40%, 750px);
    background-color: #494949;
    padding: 6px;
    padding-bottom: calc(0.83em + 6px);
}
.ban-popup-box a{
    text-decoration: none;
}


.thread-labels{
    display: flex;
    padding: 8px 0px;
    align-items: center;
}

.thread-labels div{
    font-size: 18pt;
}

.thread-label{
    flex: 1;
    text-align: center;
}

#convo-link{
    text-decoration: underline;
}

#convo-link:hover{
    cursor: pointer;
}

#btn-backdrop{
    border: 1px solid black;
    box-shadow: 7px 4px black;
}