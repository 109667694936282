.report-button, .block-button{
    display: inline-block;
    width: clamp(100px, 15%, 160px);
    color: white;
    text-align: center;
    font-size: 16px;
    background-color: #ff6060;
    padding: 8px 0px;
    border: 1px solid black;
    margin-left: 10px;

    box-shadow: 7px 4px black;
}
.report-button:hover, .block-button:hover{
    cursor: pointer;
    background-color: #ff7373;
}

.report-comment-box {
    width: 50%;
    height: 60px;
}

.report-box-title{
    font-size: 24pt;
    margin: 15px;
}
.report-popup-box{
    width: 400px;
    background-color: #494949;
    font-size: 0.5em;
    padding: 6px;
    padding-bottom: calc(0.83em + 6px);
    margin: auto;
}

.submit-report{
    width: 50%;
    flex: 1;
    color: white;
    text-align: center;
    font-size: 16px;
    background-color: #ff6060;
    margin-top: 10px;
    padding: 8px 0px;
}
.submit-report:hover{
    cursor: pointer;
    background-color: #ff7373;
}
.back-btn-wrapper{
    width: 30%;
    padding-top: 15px;
    margin: auto;
}