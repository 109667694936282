.game-stats-box-header{
    width: 100%;
    font-size: 1.2em;
    display: flex;
    align-items:center;
    background-color: #444444;
}
.stats-box-game-img{
    width: 70px;
    aspect-ratio: 1/1;
    border-radius: 10%;
}
.stats-box-title-container{
    padding-left: 8px;
    font-weight: bold;
}
.game-stats-box {
    width: 300px;
    height: 500px;
    background-color: #222222;
    text-align: left;
    color: #ffffff;
    margin-left: 10px;
    margin-top: 20px;
}

.game-stats-box-body {
    padding-left: 10px;
    padding-top: 10px;
}

.stat-line {
    padding-top: 20px;
}