.optbox {
    margin-top: 20px;
    opacity: 1;
}

#league-link-img img{
    padding-top: 10px;
    margin-top: 10px;
}

#league-link-img :hover{
    cursor: pointer;
}

.label {
    display: flex;
}

#labelHeader {
    margin-left: 20px;
}

.label select, .label input {
    margin-top: 10px;
    margin-left: 20px;
    height: 30px;
    position: absolute;
    left: calc(15vw + 30px);

}

.linkBox{
    padding-top: 20px;
}