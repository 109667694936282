#bio-field {
    resize: none;
}

.addGame {
    width: 50%;
    float: right;
}

.profile-editor {
    width: 50%;
    float: left;
}

#account-info-header {
    margin-left: 5px;
    margin-right: 15px;
}

label {
    color: #ffffff;
}

label .regfield {
    color: #FFFFFF;
    float: left;
    clear: both;
    font-size: large;
    width: 200px;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 5px;
}

label input,
label textarea {
    margin-bottom: 10px;
    width: 20vw;
}

label select {
    margin-bottom: 10px;
    width: 20.5vw;
}

#bio-header,
#bio-field {
    min-height: 80px;
}

.editor-body {
    max-width: 80vw;
    display: flex;
    background-color: #333333;
    margin-left: auto;
    margin-right: auto;
}

#edit-img-src {
    object-fit: cover;
    width: 100px;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}

#edit-img {
    margin-top: 5px;
    padding-bottom: 10px;
}

#upload-button {
    display: flex;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

#submitupdate {
    margin-bottom: 10px;
    flex: 1;
    color: black;
    text-align: center;
    font-size: 16px;
    background-color: #F9C846;
    padding: 8px 0px;
    border: 1px solid black;
    box-shadow: 7px 4px black;
    width: 80px;
}

#submitupdate:hover {
    cursor: pointer;
    background-color: #f3cb5e;
}

#submitupdate:disabled {
    cursor: default;
    color: #646464;
    background-color: #888888;
}