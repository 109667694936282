#resetbutton {
    font-size: 16pt;
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.forgfield input {
    margin-left: 20px;
    width: 10vw;
    min-width: 200px;
    max-width: 200px;
    margin-top: auto;
    margin-bottom: auto;
}

.forgfield{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    font-size: 16pt;
}
