.msg-sender {
    color: white;
    height: 50px;
    padding: 0px 10px;
}

.loading {
    text-align: center;
}

.sender-form{
    display: flex;
    width: 100%;
}

.msg-sender-submit-button {
    height: 45px;
    flex:1;
}

.msg-sender-text-box {
    flex:9;
    margin-right: 10px;
}

.msg-sender-text {
    height: 40px;
    width: 100%;
    padding: 0px;
}

.thread-wrapper{
    padding-bottom: 10px;
}

.messagepage {
    width: clamp(1000px, 80%, 1300px);
    padding-top: 0px;
    margin: 10px auto;
}

.inbox {
    margin-top: 20px;
    color: white;
    display: flex;
}

.threadpreview {
    background-color: #666666;
    min-height: 70px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
}

.threadpreview-selected {
    background-color: #333333;
    min-height: 70px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
}

.thread-preview-set {
    min-width: 300px;
    margin: 0px;
    background-color: #222222;
}

.tp-header-wrapper{
    width: 100%;
    background-color: #333333;
    border-bottom: 1px solid black;
    height: 40px;
    padding: 10px 0px;
}
.tp-header-text{
    font: 1.5em sans-serif;
    width: 90%;
    margin: auto;
}

.threadfull {
    background-color: #333333;
}

.thread-holder-err {
    width: 100%;
    text-align: center;
    font-size: 40pt;
}
.thread-holder-err-text{
    font-size: 18pt;
    padding-bottom: 10px;
}

.thread-header {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    margin: 0px;
    border-bottom: 1px solid black;
    align-items: center;
}

.thread-username{
    flex: 1;
    font-size: 14pt;
    text-align: left;
    margin-left: 8px;
}

.threadpreview:hover {
    background-color: #888888;
}

.thread-box {
    float: right;
    width: 80%;
    border-left: 1px solid black;
}

.thread-holder {
    background-color: #333333;
    text-align: left;
    font-size: 24pt;
    
}

.thread-msgs {
    background-color: #333333;
    display: flex;
    flex-direction: column-reverse;
    padding: 0px 10px;
    overflow: auto;
    height:70vh;
}

.spacer {
    background-color: #222222;
}

.thread-preview-box:hover{
    cursor: pointer;
}

.thread-preview-icon {
    width: 12.5%;
    float: left;
    padding-right: 20px;
}

.thread-preview-side {
    width: 70%;
    float: right;
    padding-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.thread-preview-side-header {
    color: #ffffff;
}

.thread-preview-side-text {
    color: #cccccc;
}

.msg-text{
    color: #111111;
    font-size: 12pt;

    margin-left: 20px;
    margin-top: 20px;

    padding: 10px 0px 10px 20px;
    
    border-radius: 25px;

    word-wrap: break-word;
}

.blocked-user-lbl{
    margin:auto;
}

.user-sent .msg-text{
    background-color: #666666;
    color: #ffffff;
    padding-right: 20px;
    margin-right: 20px;
}

.user-sent, .user-received {
    width: 60%;
}

.user-sent {
    margin-left: 40%;
    margin-right: 0px;
    height: fit-content;
}

.user-received {
    height: fit-content;
}

.msg-time{
    font-size: 8pt;
}
.user-received .msg-time {
    margin-left: 40px;
    text-align: left;
}
.user-sent .msg-time {
    float: right;
    margin-right: 40px;
}

.user-received .msg-text {
    background-color: #F9C846;
}

#thread-preview-icon-src{
    object-fit: cover;
    width: 60px;
    height: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}

.thread-preview-icon{
    padding-left: 10px;
}

#thread-full-icon-src{
    object-fit: cover;
    width: 40px;
    height: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}

.thread-full-icon{
    padding-left: 10px;
}

