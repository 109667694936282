.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page {
  width: max(6000px ,90%, 90vw);
  max-width: 1000px;
  margin: 8px auto 0px auto;
  padding-top: 6px;
  background-color: #2c2c2c;
}

.title {
  text-align: center;
}

.home-body{
  text-align: center;
}


.credentials-body {
  text-align: center;
}

body {background-color: #222222;}

.loading {
  text-align: center;
  color: white;
}

.btn-backdrop{
    box-shadow: 7px 4px black;
}