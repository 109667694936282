.banner {
    text-align: center;
    color: #ffffff;
    background-color: #666666;
    align-content: center;
    text-overflow: ellipsis;
}
.banner:hover{
    cursor: pointer;
    color: #F9C846;
}