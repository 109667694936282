#profile-wrapper{
    width: max(6000px ,90%, 90vw);
    max-width: 1000px;
    background-color: #333333;
    padding-top: 20px;
    padding-bottom: 20px;
}

#prof-img-wrapper{
    float:left;
    padding-left: 20px;
    min-height: 200px;
    padding-right: 40px;
}

#prof-info{
    text-align: center;
}

.prof-caption, .prof-header{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    color: #ffffff;
    text-align: left;
}

.prof-caption {
    font-size: 16pt;
}

.prof-header {
    margin-bottom: 10px;
}

#numtext {
    padding-left: 5px;
}

.prof-text {
    text-align: left;
    margin-bottom: 20px;
    color: #FFFFFF;
}

#edit-button {
    margin-left: 10px;
    width: 100px;
}

#prof-header {
    font-size: 20pt;
}

#prof-cap-box {
    padding-top: 10px;
    padding-bottom: 5px;
    background-color: #222222;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 160px;
    margin-right: 50px;
}

#prof-img-src{
    object-fit: cover;
    width: 100px;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}

#prof-img-wrap{
    margin-top: 30px;
    padding-left: 10px;
}