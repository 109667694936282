.signup-box{
    width: max(6000px ,90%, 90vw);
    max-width: 800px;
    display: inline-block;
    text-align: center;
    background-color: #272727;
}

.signup-box .regdiv {
    margin-left: 200px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.signup-box .regdiv .text {
    min-width: 200px;
}

.signup-box .signerrmsg{
    color: #FFFFFF;
}

.signup-box .regfield {
    color: #FFFFFF;
    float: left;
    clear: both;
    font-size: large;
    width: 500px;
    text-align: left;
}

.signup-box .forminput {
    margin-right: 20px;
    float: right;
    width: 300px;
}

.signup-box h2 {
    color: #FFFFFF;
    font-size: 60pt;
    margin-top: 30px;
    margin-bottom: 30px;
}

.subdiv {
    background-color: #F9C846;
    border: 1px solid black;
    text-align: center;
    margin-top: 10px;
    height: 5vh;
    width: 10vw;
    font-size: 20pt;
    margin-bottom: 10px;
}

.subdiv:hover{
    cursor: pointer;
    background-color: #f3cb5e;
}

.subdiv:disabled{
    cursor: default;
    background-color: #888888;
}

.logtext {
    color: #FFFFFF;
}

.logbox {
    margin-top: 10px;
    margin-bottom: 20px;
    color: #FFFFFF;
}

.passvalidation {
    color: #FFFFFF;
    width: 100%;
    text-align: center;
}

.passvalidation .label {
    align-self: center;
}

.endform {
    margin-top: 20px;
}