.popup-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.199);
    backdrop-filter: blur(5px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.popup-wrapper {
    z-index: 2;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color:white;
}

.popup-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}