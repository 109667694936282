.general-button{
    flex: 1;
    color: black;
    text-align: center;
    font-size: 16px;
    background-color: #F9C846;
    padding: 8px 0px;
    border: 1px solid black;
}
.general-button:hover{
    cursor: pointer;
    background-color: #f3cb5e;
}

.gbg1{
    background-color: #F9C846;
}
.gbg1:hover{
    background-color: #f3cb5e;
}

.gbg2{
    background-color: #ff6060;
}
.gbg2:hover{
    background-color: #ff7373;
}