#Loading{
    background-color: #ffffff;
}

#loader{
  position:relative;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  width: 90px;
}

#loader span {
  align-content: center;
}
/*THIS IS THE STYLE SHEET FOR THE BASE PAGES, COMPONENTS HAVE CSS IN THEIR FOLDERS*/

.page {
    width: max(6000px ,90%, 90vw);
    max-width: 1000px;
    margin: 8px auto 0px auto;
    background-color: #333333;
    color: #FFFFFF;
  }

.title {
    text-align: center;
  }

/*CREDENTIALS PAGE STYLES**********************************************************/
.credentials-body {
    text-align: center;
    margin-top: 5vh;
}

/*HOMEPAGE STYLES**********************************************************/
.home-body{
    text-align: center;
}

/*MATCHES PAGE STYLES**********************************************************/
.match-body{
    width: 100%;
    padding-bottom: .5vh;
}

.player-filter{
  width: 50%;
  color: white;
  margin: auto;
  align-items: center;
  text-align: center;
  background-color: #272727;
  padding:10px;
}

.matches-page{
    background-color: rgba(0, 0, 0, 0);
    width: 75%;
}

.sel-sizing{
    width: 50%;
}

.find-match-btn{
    width: 50%;
}

.submit-match-params{
    width: 50%;
    min-width: 130px;
    aspect-ratio: 3/1;
    color: black;
    text-align: center;
    font-size: 20px;
    background-color: #F9C846;
    margin-top: 10px;
    padding: 8px 0px;
    border: 1px solid black;
}.submit-match-params:hover{
    cursor: pointer;
    background-color: #f3cb5e;
}

.more-matches-box{
  text-align: center;
  padding-bottom: 8px;
}

.filters-toggle-btn{
    width: 80px;
    margin-left: auto;
    margin-right: 16px;
}

#nomatchtext{
  text-align: center;
  color: #FFFFFF;
}

.matches-flex{
    display: flex;

}

.matches-filter-col{
    flex: 1;
    height: 400px;
    min-width: 80px;
}

.matches-content-col{
    flex: 9;
    display:flex;
    flex-direction: column;
    background-color: #333333;
    padding-top: 10px;
}

.game-selector{
    display: flex;
    align-items: center;
    width: 100%;
    aspect-ratio: 1/1;
    background-color: #666666;
}.game-selector:hover{
    cursor: pointer;
}

.selected-game{
    background-color: #333333;
}

.game-sel-ico{
    width: 80%;
    height: 80%;
    margin: auto;
    aspect-ratio: 1/1;
}

/*MESSAGE PAGE STYLES**********************************************************/

/*PROFILE PAGE STYLES**********************************************************/

.loading {
  color: #ffffff;
}

.err-msg {
  color: #ffffff;
  font-size: 24pt;
  text-align: center;
  padding: 20px;
  max-width: 60vw;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: #333333;
}

/*PROFILE EDITOR STYLES********************************************************/
.editor-body {
  margin-top: 20px;
}

#edit-title-text {
  color: #ffffff;
  text-align: center;
  font-size: 32pt;
}

#account-info-header {
  color: #ffffff;
  margin: 0px;
}

/*ADMIN PAGE STYLES************************************************************/


/*VERIFIER PAGE STYLES************************************************************/
.ver-page {
  color: #FFFFFF;
}
